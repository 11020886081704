import React, { useState, useRef, useEffect } from 'react';
import { VStack, Button, Box, Text, Container, Heading, Image, Flex, Input, FormControl, FormLabel } from "@chakra-ui/react";
import axios from 'axios';
import socketIOClient from 'socket.io-client';

function App() {
  const [file, setFile] = useState(null);
  const [messages, setMessages] = useState([]);
  const [inUpload, setInUpload] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const consoleEndRef = useRef();
  const [fileName, setFileName] = useState(null);
  const [fileNameFormat, setFileNameFormat] = useState('[B]_MG[MG]');
  const [currentTimestamp, setCurrentTimestamp] = useState(null);

  const sendMessage = (message) => {
    setMessages(prevMessages => {
      const newMessages = [...prevMessages, `${formatDate(new Date())} - ` + message];
      setTimeout(() => {
        consoleEndRef.current.scrollTop = consoleEndRef.current.scrollHeight;
      }, 0);
      return newMessages;
    });
  };

  const HandleFileNameFormatChange = (event) => {
    const newFileNameFormat = event.target.value;
    setFileNameFormat(newFileNameFormat);
  };

  const handleFileChange = (e) => {
    if (e.target.files[0] && e.target.files[0].type === 'application/pdf') {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    } else {
      alert('Bitte laden Sie nur PDF-Dateien hoch.');
    }
  };

  const uploadFile = async () => {
    const currentTimestamp = new Date().getTime();
    setCurrentTimestamp(currentTimestamp);
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileNameFormat', fileNameFormat);
      formData.append('currentTimestamp', currentTimestamp);
      await axios.post('/api/upload', formData);
      sendMessage('Datei erfolgreich hochgeladen');
      setInUpload(true);
    } catch (error) {
      sendMessage(`Fehler beim Hochladen der Datei: ${error}`);
    }
  };

  const downloadZip = async () => {
   
    axios.get('/api/download?currentTimestamp=' + currentTimestamp, { responseType: 'blob' })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName + '.zip');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Fehler beim Herunterladen der Datei:', error);
      });
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    const socket = socketIOClient('https://speamsplit.de:8080', {
      secure: true,
      rejectUnauthorized: false, // Nur für Entwicklungszwecke, in Produktion Zertifikatvalidierung aktivieren
    });

    socket.on('eventMessage', (data) => {

      if (String(data.message.currentTimestamp) === String(currentTimestamp)) {
        sendMessage(data.message.message);

        if (data.message.message.includes('Processing took')) {
          setIsCompleted(true);
        }
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [currentTimestamp]);

  return (
    <Container centerContent bg="gray.200" maxW="100%" minH="100vh" p={4}>
      <Image src="/logo.png" boxSize="30%" alt="Logo" />
      <Heading mb={5}>Der Feuerwehrlaufkarten Splitter</Heading>
      <Flex direction={["column", "row"]} w="full" maxW="800px">
        <VStack flex={1} spacing={5} mr={[0, 5]} mb={[5, 0]}>
          <FormControl w="full" p={5} bg="white" borderRadius="lg" boxShadow="lg">
            <FormLabel color="gray" cursor="pointer">
              {file ? file.name : 'Wählen Sie Ihre PDF-Datei'}
            </FormLabel>
            <Input type="file" accept=".pdf" onChange={handleFileChange} isDisabled={file} style={{ display: 'none' }} />
          </FormControl>
          <FormControl w="full" p={5} bg="white" borderRadius="lg" boxShadow="lg">
            <FormLabel color="gray" htmlFor="fileInput">
              Geben Sie Ihren Export Dateinamen ein:
            </FormLabel>
            <Text fontSize={12} color="gray">[MG] für die erkannte Meldergruppe und [B] für das Kürzel der Seite (H = Rückseite / V = Vorderseite).</Text>
            <Input type="text" defaultValue="[B]_MG[MG]" onChange={HandleFileNameFormatChange}/>
          </FormControl>
          {isCompleted ? <Button onClick={downloadZip} colorScheme="teal" borderRadius="full">Download ZIP</Button> : null }
          {inUpload && !isCompleted ? <Text fontSize={15} color="teal">Bitte warten Sie, bis der Vorgang abgeschlossen ist.</Text> : null}
          {!inUpload ? <Button onClick={uploadFile} colorScheme="blue" isDisabled={!file} borderRadius="full">Datei hochladen</Button> : null }
        </VStack>
        <Box ref={consoleEndRef} flex={1} p={5} bg="white" borderRadius="lg" boxShadow="lg" overflowY="auto" minH="200px" maxH="200px">
          {messages.map((message, index) => (
            <Text color="gray" fontSize={12} key={index}>{message}</Text>
          ))}
        </Box>
      </Flex>
    </Container>
  );
}

export default App;
